import styled from "styled-components";
import ContentBox from "../ContentBox";
import SwiperComponent from "../SwiperComponent";

export default function GuideSlideBox(props: {
  initialSlideNum?: number;
  goNextOnceAndStop?: boolean;
}) {
  return (
    <>
      {/* <ContentBox
        header={<div className="as-c">연간 부킹 이용 안내</div>}
        content={ */}
      <ContentContainer>
        <SwiperComponent
          initialSlide={props.initialSlideNum}
          goNextOnceAndStop={props.goNextOnceAndStop}
          slideContentList={[
            <img
              src="https://api.v2.teeshot.co.kr/event/resource/images/year-guide-1.png"
              alt=""
            />,
            <img
              src="https://api.v2.teeshot.co.kr/event/resource/images/year-guide-2.png"
              alt=""
            />,
            <img
              src="https://api.v2.teeshot.co.kr/event/resource/images/year-guide-3.png"
              alt=""
            />,
            <img
              src="https://api.v2.teeshot.co.kr/event/resource/images/year-guide-4.png"
              alt=""
            />,
          ]}
          swiperKey="yb-guide"
        />
      </ContentContainer>
      {/* }
      /> */}
    </>
  );
}

const ContentContainer = styled.div`
  .swiper {
    z-index: 0;
  }
  .swiper-scrollbar,
  .swiper-button-next,
  .swiper-button-prev {
    display: none;
  }
  img {
    width: 100%;
  }
  .swiper-pagination {
    position: absolute;
    bottom: 15px;
    left: 15px;
    width: auto;
  }
  .swiper-pagination-bullet {
  }
  .swiper-pagination-bullet-active {
    background-color: #3e3e3e;
  }
`;
