import React, { ReactNode } from "react";
import { Accordion } from "src/components";

// import { Accordion, AccordionItem as Item } from "@szhsin/react-accordion";
import styled from "styled-components";
import { FaQuora } from "react-icons/fa";

const FAQ_TAMPLATES: { title: ReactNode; content: ReactNode }[] = [
  {
    title: "연단체 신청은 몇팀부터 가능한가요?",
    content:
      "신청접수는 보통 최소 3팀(1팀 4인)부터 가능하며, 골프장에 따라 4팀부터 예약이 가능할수도 있습니다.",
  },
  {
    title: "연간 이용회수는 몇회 이상인가요?",
    content:
      "2월~12월중 보통 년 8회 이상 이용하여야 하며, 골프장에 따라 약간 변동이 있을수 있습니다.",
  },
  {
    title: "희망 골프장은 몇 개를 할수 있나요?",
    content:
      "원하시는 골프장 3개를 신청하실 수 있으며 그중 신청조건을 수락한 골프장 중에서 선택하실 수 있습니다.",
  },
  {
    title: "연단체 매칭수수료는 얼마인가요?",
    content:
      "연단체 신청에 대한 골프장 매칭수수료는 무료이며, 매월 라운드 부킹수수료는 팀당 1만원입니다.\n\n상기 수수료는 그린피 금액 2% 수준의 최저가격이며, 골프장과의 협력비용과 서비스 운영과 개선에 사용됩니다.",
  },
  {
    title: "보증금은 무엇인가요?",
    content:
      "단체팀 노쇼(No-Show)의 발생은, 골프장에 손실을 발생하기 때문에 이를 방지하기 위하여 각 골프장에 따라 보증금 예치여부와 예치금액을 고지하고 있습니다.",
  },
  {
    title: "예약 및 취소규정은 어떻게 되나요?",
    content:
      "각 골프장 규정에 따라 조금씩 상이하기 때문에 골프장의 모집공고와 예약 및 취소규정을 확인하신 후에 선택하시기 바랍니다.",
  },
  {
    title: "위약금은 어떤 상황에서 발생하나요?",
    content:
      "1. 취소기한 이후 예약취소시 별도 위약금이 발생합니다.\n\n2. 예약팀수 위반(초과, 미달) 및 NO-SHOW 등 준수사항 위반시 익월 단체팀 예약제한 및 별도 위약금이 발생합니다.\n\n3. 위 사항은 보통 동일하게 적용되며 골프장에 따라 약간 변동이 있을수 있습니다.",
  },
];

export default function FAQ() {
  return (
    <Continaer className="flex-1 page-pd-negative-all">
      <Accordion
        tamplates={FAQ_TAMPLATES.map((t) => {
          return {
            ...t,
            title: (
              <div className="d-flex gap-1">
                <FaQuora
                  className="sm-mt"
                  //  size={20}
                />
                {t.title}
              </div>
            ),
          };
        })}
      />
    </Continaer>
  );
}

const Continaer = styled.div`
  white-space: pre-wrap;
  /* padding-top: 15px; */
  padding: 10px;
  background-color: #c5e0b4;
  .sm-mt {
    margin-top: 3px;
  }
`;
