import { useRecoilState } from "recoil";
import ContentBox from "../ContentBox";
import { recoilStates } from "src/recoil/recoilStates";
import Button from "../Button";
import InvitationCodeForm from "./InvitationCodeForm";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import Stepper from "../Stepper";
import GuideSlideBox from "./GuideSlideBox";

export default function NoYearGroupContent() {
  const [group] = useRecoilState(recoilStates.group);
  const hasGroup = Boolean(group.id);

  const navigate = useNavigate();
  return (
    <Container className="d-flex fd-c gap-2 flex-1">
      <Stepper
        stepsXpadding="100px"
        className="page-pd-negative-side page-pd-negative-top"
        currentIdx={null}
        title={"단체 등록하세요~"}
        steps={[
          { label: <>단체등록</>, icon: "group-register" },
          { label: "부킹 신청", icon: "booking-register" },
        ]}
      />
      <GuideSlideBox initialSlideNum={0} goNextOnceAndStop={true} />
      <div className="d-flex fd-c gap-2 flex-1 mt-4">
        <Button
          $colorTheme="green"
          $round={true}
          $buttonSize="md"
          onClick={() => navigate("/year-booking/group-register")}
        >
          단체 등록
        </Button>
      </div>
    </Container>
  );
}

const Container = styled.div`
  .group-top {
    display: flex;
    align-items: center;
    gap: 10px;
    .group-img {
      width: 50px;
      height: 50px;
      border-radius: 999px;
    }
  }
`;
