import { createGlobalStyle, css } from "styled-components";
import reset from "styled-reset";
import { colors } from "./theme";
import markdownStyle from "./markdownStyle";

const GlobalStyle = createGlobalStyle`

  ${() => {
    return css`
      ${reset}
      ${markdownStyle}
      *, *::before, *::after {
        box-sizing: border-box;
        -webkit-tap-highlight-color: transparent;
        /* color: ${colors.black.default} */
        /* color: #1d1d1d */
        scrollbar-gutter: stable;
      }

      body,
      html,
      #root {
        font-family: "Helvetica", "Arial", sans-serif;
        line-height: 1.5;
        max-width: 800px;
        margin: 0 auto;
        min-height: 100dvh;
      }
      #root {
        display: flex;
        flex-direction: column;
      }

      input,
      textarea {
        font-family: Arial;
        color: #333;
      }
      .header-height {
        height: 55px !important;
        min-height: 55px !important;
        max-height: 55px !important;
      }

      /* .page-height {
        height: calc(100dvh - 55px) !important;
      } */
      .break-keep {
        word-break: keep-all !important;
      }

      .page-default {
        padding: 15px;
        height: calc(100dvh - 55px) !important;
        display: flex;
        flex-direction: column;
        overflow-y: auto;
      }
      .page-pd {
        padding: 15px !important;
      }
      .page-pd-negative-side {
        margin-left: -15px !important;
        margin-right: -15px !important;
      }
      .page-pd-negative-top {
        margin-top: -15px !important;
      }
      .page-pd-negative-all {
        margin-left: -15px !important;
        margin-right: -15px !important;
        margin-top: -15px !important;
        margin-bottom: -15px !important;
      }
      .d-flex {
        display: flex !important;
      }
      .fd-c {
        flex-direction: column !important;
      }
      .jc-c {
        justify-content: center !important;
      }
      .jc-e {
        justify-content: flex-end !important;
      }
      .jc-sb {
        justify-content: space-between !important;
      }
      .jc-sa {
        justify-content: space-around !important;
      }

      .f-1 {
        flex: 1 !important;
      }
      .ai-e {
        align-items: flex-end !important;
      }
      .ai-c {
        align-items: center !important;
      }
      .as-c {
        align-self: center !important;
      }
      .as-str {
        align-self: stretch !important;
      }
      .as-s {
        align-self: flex-start !important;
      }
      .as-e {
        align-self: flex-end !important;
      }
      .gap {
        gap: 5px !important;
      }
      .gap-s {
        gap: 2.5px !important;
      }
      .gap-1 {
        gap: 5px !important;
      }
      textarea {
        padding-top: 10px;
        padding-bottom: 10px;
      }
      .input-box-type {
        border-style: solid;
        border-width: 1px;
        border-color: ${colors.gray.second};
        border-radius: 7.5px;
        padding-left: 10px;
        padding-right: 10px;
        font-size: 16px;
        height: 45px;
        /* outline: auto; */
        outline-color: #d3d3d300;
        outline-offset: 2px;
        &:focus {
          outline: auto;
          border-color: #00000000;
          outline-color: ${colors.blue.third};
        }
        &::placeholder {
          font-size: 12px;
        }
      }
      .focus-outline {
        /* outline: auto; */
        outline-color: #d3d3d300;
        outline-offset: 2px;

        &:focus {
          outline: auto;
          border-color: #00000000;
          outline-color: ${colors.blue.third};
        }
      }

      @supports (-webkit-touch-callout: none) {
        /* CSS specific to iOS devices  아이폰*/
        * {
          outline-offset: 0px !important;
        }
      }

      .w-100 {
        width: 100% !important;
      }
      .h-100 {
        height: 100% !important;
      }

      .cursor-p {
        cursor: pointer !important;
      }
      .no-click {
        pointer-events: none !important;
      }
      .bold {
        font-weight: bold !important;
      }
      .fw-b {
        font-weight: bold !important;
      }
      .fw-n {
        font-weight: normal !important;
      }
      .fc-red {
        color: red !important;
      }
      ${() => {
        let cssString = "";
        for (let i = 1; i <= 10; i++) {
          cssString += `
            .gap-${String(i)} {
              gap: ${String(5 * i)}px;
            }

            .mb-${String(i)} {
              margin-bottom: ${String(5 * i)}px !important;
            }
            .mt-${String(i)} {
              margin-top: ${String(5 * i)}px !important;
            }
            .ml-${String(i)} {
              margin-left: ${String(5 * i)}px !important;
            }
            .mr-${String(i)} {
              margin-right: ${String(5 * i)}px !important;
            }
            .pd-${String(i)} {
              padding: ${String(5 * i)}px !important;
            }
            .pt-${String(i)} {
              padding-top: ${String(5 * i)}px !important;
            }
            .pb-${String(i)} {
              padding-bottom: ${String(5 * i)}px !important;
            }
            .pl-${String(i)} {
              padding-left: ${String(5 * i)}px !important;
            }
            .pr-${String(i)} {
              padding-right: ${String(5 * i)}px !important;
            }
            .fs-${String(i)} {
              font-size: ${String(8 + 2 * i)}px !important;
            }
            .flex-${String(i)} {
              flex: ${i} !important;
            }
            
          `;
        }
        return cssString;
      }}
    `;
  }}


`;

export default GlobalStyle;
