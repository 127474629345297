import React from "react";
import styled, { css } from "styled-components";

const markdownStyle = css`
  .md {
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, Arial, sans-serif,
      "Apple Color Emoji", "Segoe UI Emoji";
    font-size: 16px;
    line-height: 1.5;
    word-wrap: break-word;
    color: #24292e;
    .red {
      color: red !important;
    }
    .blue {
      color: blue !important;
    }
    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
      margin-top: 24px;
      margin-bottom: 16px;
      font-weight: 600;
      line-height: 1.25;
    }

    h1 {
      font-size: 2em;
      padding-bottom: 0.3em;
      border-bottom: 1px solid #eaecef;
    }

    h2 {
      font-size: 1.5em;
      padding-bottom: 0.3em;
      border-bottom: 1px solid #eaecef;
    }

    h3 {
      font-size: 1.25em;
    }

    h4 {
      font-size: 1em;
    }

    h5 {
      font-size: 0.875em;
    }

    h6 {
      font-size: 0.85em;
      color: #6a737d;
    }

    p {
      margin-top: 0;
      margin-bottom: 16px;
    }

    a {
      color: #0366d6;
      text-decoration: none;

      &:hover {
        text-decoration: underline;
      }
    }

    strong {
      font-weight: 600;
    }

    em {
      font-style: italic;
    }

    ul,
    ol {
      padding-left: 2em;
      margin-top: 0;
      margin-bottom: 16px;

      ul,
      ol {
        margin-top: 0;
        margin-bottom: 0;
      }
    }

    li {
      word-wrap: break-all;

      & + li {
        margin-top: 0.25em;
      }
    }

    blockquote {
      padding: 0 1em;
      color: #6a737d;
      border-left: 0.25em solid #dfe2e5;
      margin: 0 0 16px 0;
    }

    code {
      padding: 0.2em 0.4em;
      margin: 0;
      font-size: 85%;
      background-color: rgba(27, 31, 35, 0.05);
      border-radius: 3px;
      font-family: "SFMono-Regular", Consolas, "Liberation Mono", Menlo, Courier, monospace;
    }

    pre {
      padding: 16px;
      overflow: auto;
      font-size: 85%;
      line-height: 1.45;
      background-color: #f6f8fa;
      border-radius: 3px;
      word-wrap: normal;

      code {
        display: inline;
        max-width: auto;
        padding: 0;
        margin: 0;
        overflow: visible;
        line-height: inherit;
        word-wrap: normal;
        background-color: transparent;
        border: 0;
      }
    }

    img {
      max-width: 100%;
      box-sizing: content-box;
    }

    hr {
      height: 0.25em;
      padding: 0;
      margin: 24px 0;
      background-color: #e1e4e8;
      border: 0;
    }

    table {
      border-spacing: 0;
      border-collapse: collapse;
      margin-bottom: 16px;

      td,
      th {
        padding: 6px 13px;
        border: 1px solid #dfe2e5;
      }

      th {
        font-weight: 600;
        background-color: black;
        color: white;
      }

      tr {
        background-color: #fff;
        border-top: 1px solid #c6cbd1;

        &:nth-child(2n) {
          background-color: #f6f8fa;
        }
      }
    }
  }
`;

export default markdownStyle;
