import { useRecoilState } from "recoil";
import ContentBox from "../ContentBox";
import { recoilStates } from "src/recoil/recoilStates";
import Button from "../Button";
import InvitationCodeForm from "./InvitationCodeForm";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import Stepper from "../Stepper";
import GuideSlideBox from "./GuideSlideBox";

export default function NoBookingContent(props: { isRejectedByAllClubs?: boolean }) {
  const navigate = useNavigate();
  return (
    <Container className="d-flex fd-c gap-2 flex-1">
      <Stepper
        className="page-pd-negative-side page-pd-negative-top"
        currentIdx={props.isRejectedByAllClubs ? 2 : 0}
        stepsXpadding={props.isRejectedByAllClubs ? "50px" : "100px"}
        title={props.isRejectedByAllClubs ? "골프장 신청 반려" : "부킹 신청하세요~"}
        steps={
          props.isRejectedByAllClubs
            ? [
                { label: <>단체등록</>, icon: "group-register" },
                { label: "부킹 신청", icon: "booking-register" },
                {
                  label: "조건 협의",
                  icon: "select-club",
                  isError: true,
                },
              ]
            : [
                { label: <>단체등록</>, icon: "group-register" },
                { label: "부킹 신청", icon: "booking-register" },
              ]
        }
      />
      <div className="flex-1 d-flex fd-c jc-sb gap-5">
        <GuideSlideBox initialSlideNum={2} goNextOnceAndStop={true} />
        <Button
          $colorTheme="green"
          $round={true}
          $buttonSize="md"
          onClick={() => navigate("/year-booking/booking-register")}
        >
          희망골프장 신청
        </Button>
      </div>
    </Container>
  );
}

const Container = styled.div`
  .group-top {
    display: flex;
    align-items: center;
    gap: 10px;
    .group-img {
      width: 50px;
      height: 50px;
      border-radius: 999px;
    }
  }
`;
