import React from "react";
import { useRecoilState } from "recoil";
import { recoilStates } from "src/recoil/recoilStates";
import styled from "styled-components";
import { RoundAccodian } from "src/components/YearBooking";
import { Divider, Label } from "src/components";
import { digit4ToTimeString } from "src/utils";
// import { RoundAccodian } from "../YearBooking";

function YearBookingCondition() {
  const [ba] = useRecoilState(recoilStates.bookingApplication);
  const cr = ba?.clubRels?.find((cr) => [4, 6].includes(cr.year_booking_status_id));

  if (!cr) {
    return <div>내역없음</div>;
  }
  return (
    <Container className="flex-1 d-flex fd-c gap-2">
      <div>
        <div className="bold fs-6">골프장 {cr.name}</div>
      </div>
      <div>
        <Label content="라운드 일정" className="bold" />
        <div className="pd-1">
          <div>
            •{" "}
            {JSON.parse(cr.month_arr_json_str ?? "")
              ?.map((m: string) => `${m}월`)
              .join(", ")}
          </div>
          <div className="d-flex gap-1">
            <div>
              •{" "}
              {JSON.parse(cr.week_arr_json_str ?? "")
                ?.map((w: string) => `${w}주차`)
                .join(", ")}
            </div>
            <div>{JSON.parse(cr.day_arr_json_str ?? "").join(", ")}요일</div>
            <div>{digit4ToTimeString(cr?.start_time || "")}</div>
          </div>
        </div>
      </div>

      <RoundAccodian clubRelData={cr} defaultOpened={true} />
      <Divider />

      <div className="d-flex">
        <div className="bold mr-1">보증금</div>
        <div>
          <div className="d-flex gap-1">
            <div>{cr?.round_deposit?.toLocaleString()} 원</div>
          </div>
        </div>
      </div>
      <Divider />
      <div className="d-flex">
        <div className="bold mr-1">객단가</div>
        <div>
          <div>{cr?.meal_price_per_person?.toLocaleString()} 원</div>
        </div>
      </div>
      <Divider />
    </Container>
  );
}

export default YearBookingCondition;

const Container = styled.div``;
