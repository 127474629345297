export const colors = {
  green: {
    first: "#0b7b4b",
    second: "#02c316",
    third: "#33b76a",
  },
  black: {
    first: "#000000",
    second: "#424242",
    default: "#1d1d1d",
  },
  gray: {
    first: "#818181",
    second: "#d1d1d1",
    third: "#ededed",
    fourth: "#f5f5f5",
    border: "#e2e8f0",
  },
  purple: {
    first: "#6675ff",
  },
  red: {
    first: "#ff0000",
  },
  yellow: {
    first: "#ffe500",
    second: "#ffc819",
    third: "#fff2cc",
  },
  blue: {
    first: "#0019ff",
    second: "#b0b8ff",
    third: "#1877f2",
  },
};
