import { useSearchParams } from "react-router-dom";
function useRemoveSearchParams(searchParamKey: string | string[]) {
  const [searchParams, setSearchParams] = useSearchParams();
  const deleteSome = (key: string) => {
    if (searchParams.has(key)) {
      searchParams.delete(key);
      setSearchParams(searchParams);
    }
  };
  const removeSearchParam = () => {
    if (typeof searchParamKey == "string") {
      deleteSome(searchParamKey);
    } else {
      // 다수키
      searchParamKey.forEach((key) => {
        deleteSome(key);
      });
    }
  };
  return removeSearchParam;
}

export default useRemoveSearchParams;
