import { ReactNode } from "react";
import { useRecoilState } from "recoil";
import { Loading, MainNavBtns } from "src/components";
import {
  HasProposal,
  HasYearBookingContent,
  NoBookingContent,
  NoYearGroupContent,
  PaymentWating,
  WaitYearBookingProposal,
} from "src/components/YearBooking";
import { useUserStepStatus } from "src/hooks";
import { recoilStates } from "src/recoil/recoilStates";
import { colors } from "src/styles/theme";
import { NavMenuNames, userStepStatus } from "src/types";
import styled, { css } from "styled-components";

const mainComponentObj: { [key in userStepStatus]: ReactNode } = {
  noGroup: <NoYearGroupContent />,
  noBooking: <NoBookingContent isRejectedByAllClubs={false} />,
  waitingProposal: <WaitYearBookingProposal />,
  rejectedAllProposal: <NoBookingContent isRejectedByAllClubs={true} />,
  hasProposal: <HasProposal />,
  waitingDeposit: <PaymentWating />,
  completeDeposit: <HasYearBookingContent />,
};

export default function YearBooking() {
  const [init] = useRecoilState(recoilStates.init);
  const userStep = useUserStepStatus();

  return (
    <Main className="flex-1 d-flex" $isUserDateFetchDone={init.isInit}>
      {init.isInit ? (
        <>
          {userStep ? (
            <>
              {mainComponentObj[userStep]}
              <MainNavBtns childNames={userStatusToMenusObj[userStep]} />
            </>
          ) : null}
        </>
      ) : (
        <Loading />
      )}
    </Main>
  );
}

const userStatusToMenusObj: Partial<{ [key in userStepStatus]: NavMenuNames[] }> = {
  noGroup: ["faq"],
  noBooking: ["faq", "group", "member"],
  rejectedAllProposal: ["faq", "group", "member"],
  waitingProposal: ["faq", "group", "member", "bookingRegisterResult"],
  hasProposal: ["faq", "group", "member", "bookingRegisterResult"],
  waitingDeposit: ["faq", "group", "member", "bookingProposalResult", "deposit"],
  completeDeposit: ["faq", "group", "member", "bookingProposalResult"],
};

const Main = styled.main<{ $isUserDateFetchDone: boolean }>`
  display: flex;
  flex-direction: column;
  ${(p) =>
    !p.$isUserDateFetchDone &&
    css`
      align-items: center;
      justify-content: center;
    `}

  .group-top {
    /* padding: 0px 10px; */
    background-color: ${colors.gray.fourth};
    margin-top: -15px;
    margin-left: -15px;
    margin-right: -15px;

    .img-box,
    img {
      width: 50px;
      height: 50px;
      border-radius: 999px;
    }
    img {
      object-fit: cover;
    }

    .group-img {
    }
  }
`;
