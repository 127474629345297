import { ReactNode } from "react";
import { styled } from "styled-components";

export default function FloatingBottom(props: {
  bottomSectionComponent: ReactNode;
  closeMe?: () => void;
  onBgClick?: () => void;
}) {
  // return <></>
  return (
    <FloatingContainer
      onClick={() => {
        props.closeMe && props.closeMe();
        props.onBgClick && props.onBgClick();
      }}
    >
      <div className="content" onClick={(e) => e.stopPropagation()}>
        {props.bottomSectionComponent}
      </div>
    </FloatingContainer>
  );
}
const FloatingContainer = styled.div`
  position: fixed;
  left: 0;
  top: 0;
  width: 100vw;
  height: 100dvh;
  background-color: #0000008d;

  /* animation */
  animation-duration: 0.2s;
  animation-name: darker;
  /* animation */

  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  .content {
    min-height: 50px;
    display: flex;
    background-color: white;
    /* animation */
    animation-duration: 0.3s;
    animation-name: up;
    /* animation */
  }

  @keyframes darker {
    from {
      background-color: inherit;
    }
    to {
      background-color: #0000008d;
    }
  }

  @keyframes up {
    from {
      transform: translateY(100%);
      /* background-color: inherit; */
    }
    to {
      transform: translateY(0);

      /* background-color: #0000008d; */
    }
  }
`;
