import { colors } from "src/styles/theme";
import { styled } from "styled-components";
import Button from "../Button";
import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { useRecoilState } from "recoil";
import { recoilStates } from "src/recoil/recoilStates";
import { bookingDummys } from "src/services/dummys";
import Stepper from "../Stepper";
import Animation from "../Animation";
import ContentBox from "../ContentBox";
import Divider from "../Divider";

export default function WaitYearBookingProposal() {
  const navigate = useNavigate();
  const [bookingApplication] = useRecoilState(recoilStates.bookingApplication);
  const clubsCount = bookingApplication?.clubRels?.length;
  const rejectedRels = bookingApplication?.clubRels?.filter(
    (cr) => cr.year_booking_status_id === 7
  );
  const reviewingRels = bookingApplication?.clubRels?.filter(
    (cr) => cr.year_booking_status_id === 1
  );

  return (
    <Container className="d-flex fd-c gap-2">
      <Stepper
        className="page-pd-negative-side page-pd-negative-top"
        currentIdx={1}
        stepsXpadding="50px"
        title="골프장에서 조건 검토중입니다."
        steps={[
          { label: <>단체등록</>, icon: "group-register" },
          { label: "부킹 신청", icon: "booking-register" },
          { label: "조건 협의", icon: "select-club" },
        ]}
      />
      <div className="ani-box d-flex jc-c ai-c">
        <Animation jsonFetchPath={`${process.env.PUBLIC_URL}/checklist.json`} loop={false} />
        {/* <Lottie animationData={animationData} loop={false} />
         */}
      </div>
      <ContentBox
        colorTheme="gray"
        content={
          <div className="flex-1 d-flex fd-c ai-c jc-c gap-1">
            <div className="flex-1 d-flex fd-c ai-c jc-c gap-1 fs-3">
              <p>신청하신 {clubsCount || 0}개 클럽에서</p>
              <p>열심히 조건을 검토하고 있어요!</p>
            </div>
            {rejectedRels?.length ? (
              <div className="as-str flex-1 d-flex fd-c gap-1">
                <Divider />
                <div className="d-flex fd-c gap-1 ai-c">
                  {rejectedRels?.map((rr) => {
                    return (
                      <div key={`rr-${rr.id}`}>
                        <span className="bold">{rr.club?.[0]?.name}</span> 거절도착 !
                      </div>
                    );
                  })}
                </div>
                <div className="d-flex fd-c gap-1 ai-c">
                  {reviewingRels?.map((rr) => {
                    return (
                      <div key={`rr-${rr.id}`}>
                        <span className="bold">{rr.club?.[0]?.name}</span> 검토중...
                      </div>
                    );
                  })}
                </div>
              </div>
            ) : null}
            <div></div>
          </div>
        }
      />
    </Container>
  );
}

const Container = styled.div`
  header {
    font-size: 16px;
    font-weight: bold;
    .left {
      color: ${colors.green.second};
    }
    .right {
      color: ${colors.gray.second};
    }
  }

  .ani-box {
    > div {
      max-width: 250px;
    }
    min-width: 1px;
  }
`;
