import axios from "axios";
import { useCallback, useEffect, useMemo, useState } from "react";
import Markdown from "react-markdown";
import remarkGfm from "remark-gfm";
import { Divider } from "src/components";
import { useAlert } from "src/hooks";
import styled from "styled-components";
import rehypeRaw from "rehype-raw";

const arr = new Array(30).fill(1);
export default function My() {
  const [count, setCount] = useState(1);
  const fn1 = () => {
    return 33;
  };
  // const handleChange = useCallback((newState: any) => {
  //   setCount(newState);
  // }, []);

  const zzzz = useMemo(() => 1, []);

  const alert = useAlert();

  const mdStr = `

  <span class="blue">내용</span>


  |111|222|333|
  |--|--|--|
  |111|222|333|

  1
  2
  3
  4


  # 연단체 운영규정

**제 1 조 【 목적 】**
본 약관은 서원레저 주식회사에서 운영하는 서원힐스(이하 “서원힐스”)를 이용하려는 연부킹 단체팀 (이하 “연단체”)의 클럽 시설물 이용 및 이에 따른 책임에 관한 사항을 규정함을 목적으로 한다.

**제** **2 조 【 구성 】**

1. 연단체 팀 구성은 3팀 이상 5팀 이내로 한다.(1팀 4인 기준)
2. 연단체로서 연간(3월~11월) 8회 이상 내장을 기본으로 운영한다.
3. 연단체 행사 시 회장 또는 총무 내장 필수이며, 고정 인원으로 단체가 구성되어야 한다.
4. 연단체 소속의 모든 회원들은 서원힐스 홈페이지(www.seowongolf.co.kr) 에 회원 가입을 한 후, 회원 명부를 제출하여야 한다.

**제 3 조 【 신청 】**

1. 매년 10월 중에 공지되는 연단체 운영 계획에 따라 차년도 연단체 신청을 하여야 한다. *기존 연단체는 매년 11월 30일부로 연단체 자격이 자동 종료되며, 신청을 원할 경우 재신청 하여야 한다.
2. 신청은 서원힐스 홈페이지에서 온라인으로 접수한다.
3. 확정 통보는 당해 연도 서원힐스가 정한 기간에 연단체 운영 대표자에게 유선 및 SMS 등으로 개별 통보하여 확정한다.
4. 선정된 연단체는 클럽 규정을 준수한다.

**제 4 조 【 예약관리 】**

1. 연단체 확정시 해당 주차, 요일, 시간대를 결정하여 이를 기준으로 매월 배정한다. 단, 서원힐스의 영업 상황에 따라 매월 신청 시간대에서 약 1시간 내외로 유동적일 수 있다.
2. 예약 확정일자, 시간대, 그린피는 매월 행사 예정일 기준 3주 전까지 회장 또는 총무에게 문자(SMS)로 통보한다.
3. 코스 배정은 이스트, 웨스트, 사우스 3개의 코스로 로테이션으로 배정된다.(특정 코스 지정 불가)
4. 배정 완료 후 행사 전체 취소는 14일 전까지, 팀 수 변경 및 시간대 조정은 경기일 7일 전까지 유선으로만 요청하여야 한다.(축소된 팀수의 조편성 통보 및 문자로 취소 요청 시 취소 불가)
5. 연단체의 조편성은 경기일 최소 3일 전까지 서원힐스에 FAX, 이메일, SMS 등으로 확정 통보하여야 한다.(조편성 당일 내장 등록 시점 변경 불가)
6. 사전 통보 없이 예약 팀 수 보다 적게 내장하는 경우 제8조 위약 관리 조항에 해당된다.
7. 예약을 불이행하는 경우에는 서원힐스에서 정한 규정에 따라 일정 기간의 예약 정지나 이용 제한, 위약금 부과 등의 조치를 취할 수 있다.

**제 5 조 【 운영 및 혜택 】**

1. 연단체는 팀당 1팀 4인 내장을 기본으로 운영한다.
2. 아래의 표와 같이 연단체 그린피는 서원힐스의 월 별 홈페이지 정상 그린피를 적용한다.

    | **시간대** | **적용 그린피 및 혜택** | **이용 조건** |
    | --- | ----------- | ----- |
    | · 1부 ▷ 06시대<br>-------------------------------------<br>· 1부 ▷ 07시대<br>-------------------------------------<br>· 2부 ▷ 10~12시대 | 월 별 정상 그린피<br>(홈페이지 공지 그린피) | ① 3월~11월 8회 진행<br>② 7~8월 중 1회이상 진행 필수<br>    (미 진행 시 9~11월 배정 불가)<br>③ 예약 팀 및 시간 준수<br>④ 매너, 에티켓<br>⑤ 식음 이용 권장 |

※ 상반기(1월-6월)기간 행사를 3회 미만 진행할 경우 하반기(7월-12월) 연단체 탈퇴 처리가 될 수 있다.
※ 상기 운영 내용과 아래 혜택은 서원힐스의 사정에 따라 변경될 수 있으며, 변경 시 고지 후 시행한다.

**제 6 조 【 수용 】**

1. 운영 기간은 3월부터 11월까지 9개월로 하며, 행사는 주중(월요일부터 금요일) 및 일요일에 한하여 진행한다. 또한 연중 12, 1, 2월의 3개월간은 일조시간 단축에 따라 경기를 제한할 수 있다. (단, 1~2월, 12월은 배정 희망단체에 한하여 운영한다.)
2. 연단체 행사는 월 1회로 제한한다.
3. 매달 연단체 배정은 약정된 팀 수로만 배정 가능하며, 2팀은 연단체로서 사전 배정 불가하다.
4. 최소 3팀 예약 후 위약 기간 전에 2팀으로 변경 진행이 2회 이상 발생했을 때 강제 탈퇴 처리 한다. (2팀 3회 진행 시점에 바로 탈퇴 처리)
5. 경기일이 공휴일인 경우 서원힐스와 협의를 통해 해당 주 다른 요일 및 다른 주 해당 요일로 조정하여야 한다.
6. 연단체 행사 시 룸 이용은 라운드 전 룸 예약이 불가하며 라운드 후 룸 예약은 선착순 예약으로 이용할 수 있다.
7. 연단체 중 행사횟수, 매너 이슈 등에 따라 이용에 제한이 있을 수 있으며, 차년도 연단체 재신청 시 제한을 둘 수 있다.
8. 서원힐스의 상황과 일조량에 따라 3부제 또는 2부제로 운영을 하며 신청 시간대는 3부제 기준이므로 2부제로 운영하는 경우 신청 시간대가 아닌 다른 시간대로 조정이 불가피하며 조정 시 시간대별 정상 그린피가 적용된다.(3월, 11월 2부제 시행 예정)
9. 골프장 이용과 관련된 규정 및 이용요금은 경기 당일 기준에 준하여 적용한다.
10. 단체 행사 시상품의 외부 반입은 지양한다.
11. 골프장 운영상 특정 사유가 있거나 자체 클럽 행사가 있을 경우 연단체 배정일이 변경될 수 있다.
12. 월별 정상 그린피는 서원힐스의 정책에 따라 변동될 수 있다.

**제 7 조 【 이용의 거절 】**
서원힐스는 다음의 경우에는 클럽의 이용을 거절하거나 퇴장을 요구할 수 있다.

1. 예약된 티타임 시간을 지키지 않는 때
2. 본 약관을 위반하였을 때
3. 경기 능력이 현저히 부족하여 다른 이용자의 경기에 큰 방해가 될 때
4. 도박성 내기를 하는 등 미풍양속에 어긋나는 행동을 할 때
5. 대한골프협회 규칙, 기타 이용자 에티켓과 관련하여 클럽이 미리 고지한 준수 사항을 위반할 때
6. 서원힐스에 근무하는 직원, 캐디들에 대한 폭언 및 성희롱을 할 때 (발생 즉시 강제 퇴장 요청, 연단체 취소 및 영구 내장 정지)
    ※ 남녀고용평등법 제2조 [별표1] 직장 내 성희롱을 판단하기 위한 기준의 예시 기준
7. 기타 사유로 서원힐스 이용에 적합하지 않다고 객관적으로 판단될 때

**제 8 조 【 위약규정 】**

1. 연단체의 운영은 해당 회장(총무)이 모든 책임을 진다.
2. 해당 월 연단체 행사 취소는 경기일 14일 전까지, 팀 수를 축소 운영할 경우 경기일 7일 전까지 서원힐스 예약실 담당자에게 유선으로만 전달하여야 한다. (축소된 팀수의 조편성 통보 및 문자로 취소 요청 시 취소 불가)
3. 3인 내장 시 해당 팀 그린피는 3.5인 요금으로 정산한다. (카트비, 캐디피는 팀 요금 그대로 적용)
4. 위약 세부규정과 위약금은 아래 표와 같다.

    | 구분 | 적용 | 위약금 |
    | --- | --- | --- |
    | 10일~7일전 | 연단체 신청 포인트 차감 | 없음 |
    | 6일~3일전 | 연단체 신청 포인트 차감 | 취소팀수 x 1팀 이용 요금의 10% |
    | 2일~1일전 | 연단체 신청 포인트 차감 | 취소팀수 x 1팀 이용 요금의 20% |
    | No-Show | 연단체 신청 포인트 차감 | 취소팀수 x 1팀 이용 요금의 30% |

    ※ 팀 이용요금 : 팀그린피+카트비
5. 가점 포인트 차감에 관련된 세부사항은 본 이용 약관의 ‘제10 조‘에 따른다.
6. 위약금 미납 시 잔여 연 부킹 취소 및 해당 연단체 회원 모두를 영구 내장 정지한다.
7. 위약규정은 회사 내부 규정 방침에 따라 일부 변경될 수 있다.

**제 9 조 【 경기진행 】**

1. 모든 이용자는 클럽 내에서 항상 신사적 에티켓과 매너를 유지하고 질서를 지켜 다른 이용자 또는 경기 진행에 지장을 주지 말아야 한다.
2. 원활한 경기 진행을 위해 최소 40분 전에 입장 등록을 마치고, Tee-Off 20분 전까지 스타트 광장에서 경기 준비를 완료하여야 한다.
3. Tee-Off 20분 전 이후 내장 시 홀 합류, 홀 점프 등 서원힐스의 현장 경기 진행에 적극 협조하여야 한다.
4. 서원힐스는 경우에 따라 경기시간에 제한을 둘 수 있으며, 경기 진행 비협조 및 비매너 시 경고 조치하고 1회 이상의 경고에도 불구하고 적절한 속도를 유지하지 못하거나 개선되지 않을 경우 해당 경기자에게 퇴장 요청을 할 수 있다.
5. 코스 내 지정된 장소 이외에서의 흡연은 불가하며, 경기 중 쓰레기를 적당한 용기에 처리함으로써 코스를 청결하게 유지하는데 협조하여야 한다.
6. 강설, 폭우, 안개, 기타 천재지변 등 불가항력적인 사유로 플레이를 할 수 없을 경우 경기를 취소할 수 있다.(취소규정 확인: 서원힐스 홈페이지→이용안내→악천후 취소규정)

**제 10 조 【 연단체 신청 포인트 제도 】**

1. 정부의 대중형 골프장 지정에 따라 문화체육관광부에서 고시한 골프장 운영 이용 약관 준수로 모든 고객(기존 연단체 회장, 총무)은 연단체 신청 시 동일기간, 동일한 조건을 통해 신청하여야 하며,
    아래의 표에 명시된 항목에 따라 모든 고객은 연단체 신청 포인트 제도가 적용되며, 연단체 모집 추첨 시 유리하게 적용될 수 있다. [3월 홈페이지 고시 예정]
2. 위 포인트 적용은 서원힐스를 이용하는 인터넷회원을 포함한 모든 고객에게 적용되며, 연단체 행사의 경우 회원 명부에 작성된 모든 회원에게 적용된다.(단, 인터넷회원 내장 체크인 필수)
    ※ 매너 불량(비매너) 예시
3. 아래 예시에 대하여 무리하게 요청하거나 반복적이고 집요한 요구 및 강압적인 태도 또는 본인의 요청사항이 받아들여지지 않을 시 해당 직원에게 지속으로 괴롭히는 행동 등
    예시) 할인, 밸리(회원제) 배정, 식사/커피 서비스, 프로모션(이벤트)과 할인 중복 적용, 특정 시간대로 이동 또는 고정, 연단체 회원의 개인 티타임 선배정 요청, 특정 코스 배정 등
4. 직원 및 캐디의 자존감 하락의 요인이 되는 태도 및 언행, 고성, 욕설, 폭언 등
5. 기타 통상적인 비매너(경기 진행 비협조, 음식물 반입 등)를 모두 포함한다.

**제 11 조【 외부반입 취식물 관련 】**
건강과 위생을 위하여 취식물 외부 반입을 제한한다 (특히 여름철 식중독 예방 차원)

**제 12 조 【 기타 】**
본 약관에 명시되지 아니한 사항 또는 해석상 다툼이 있는 사항에 대해서는 일반 관례에 따라 합의하여 결정하되, 합의가 이루어지지 아니한 경우에는 민사소송법상의 관할 법원에 제기한다.

부 칙
본 약관은 2025년 1월 01일부터 시행한다

  `;

  return (
    <>
      <div className="md">
        <Markdown remarkPlugins={[remarkGfm]} rehypePlugins={[rehypeRaw]}>
          {mdStr}
        </Markdown>
      </div>
      <Container className="ffff">
        <hr />
        accept="image/png, image/gif, image/jpeg{" "}
        <input type="file" accept="image/png, image/gif, image/jpeg" />
        <hr />
        image/png,image/jpeg
        <input type="file" accept="image/png,image/jpeg" />
        <hr />
        image/*
        <input type="file" accept="image/*" />
        <hr />
        capture u accept="image/png, image/gif, image/jpeg{" "}
        <input type="file" accept="image/png, image/gif, image/jpeg" capture="user" />
        <hr />
        capture u image/png,image/jpeg
        <input type="file" accept="image/png,image/jpeg" capture="user" />
        <hr />
        capture u image/*
        <input type="file" accept="image/*" capture="user" />
        <hr />
        capture e accept="image/png, image/gif, image/jpeg{" "}
        <input type="file" accept="image/png, image/gif, image/jpeg" capture={"environment"} />
        <hr />
        capture e image/png,image/jpeg
        <input type="file" accept="image/png,image/jpeg" capture={"environment" as any} />
        <hr />
        capture e image/*
        <input type="file" accept="image/*" capture={"environment" as any} />
        <hr />
        <div>
          <button
            onClick={() => {
              (async () => {
                alert({ content: "alert1", onClose: () => alert.resume() });
                await alert.pause();
              })();
            }}
          >
            alert 1
          </button>
        </div>
        <div>
          <button
            onClick={() => {
              (async () => {
                alert({ content: "alert1", onClose: () => alert.resume() });
                await alert.pause();
              })();
            }}
          >
            alert 2
          </button>
        </div>
        <div>
          <button
            onClick={() => {
              (async () => {
                alert({ content: "alert2", onClose: () => alert.resume() });
                await alert.pause();
              })();
            }}
          >
            alert 3
          </button>
        </div>
        <div>
          <button
            onClick={() => {
              (async () => {
                alert({ content: "alert3", onClose: () => alert.resume() });
                await alert.pause();
              })();
            }}
          >
            alert 4
          </button>
        </div>
        <div>{count}</div>
        <button
          onClick={() => {
            setCount((c) => c + 1);
          }}
        >
          d
        </button>
        {/* <div className="inner">
        {arr.map((x) => {
          return <div className="cc">{x}</div>;
        })}
      </div> */}
        <Chil zz={fn1} />
      </Container>
    </>
  );
}

const Container = styled.div`
  /* flex: 1;
  min-height: 0;
  height: 100%;

  overflow: hidden;

  .inner {
    height: 100%;
    overflow: auto;
    background-color: coral;
    .cc {
      margin: 5px;
      background: #000;
    }
  } */
`;

const Chil = (props: { zz: any }) => {
  // console.log(props.zz);

  useEffect(() => {
    // console.log(" 나: load");
  }, []);

  return <div>나나</div>;
};
