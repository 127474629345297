import { useCallback, useEffect, useRef, useState } from "react";
import React from "react";
import styled, { css } from "styled-components";
import Label from "./Label";
import Button from "./Button";
import { IoSearchOutline } from "react-icons/io5";
import { colors } from "src/styles/theme";
import { fileToBase64 } from "src/utils";
import { useAlert } from "src/hooks";
import { RiImageAddLine } from "react-icons/ri";

const useInput = (initValue: string = "", regex?: RegExp) => {
  const [value, setValue] = useState<string>(initValue);
  const resetValue = () => {
    setValue(initValue);
  };

  useEffect(() => {
    setValue(initValue);
  }, [initValue]);

  const onChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    if (regex) {
      if (!regex.test(e.target.value)) {
        return;
      }
    }
    setValue(() => e.target.value);
  }, []);

  return {
    value,
    onChange,
    resetValue,
  };
};

const useFileInput = () => {
  const [imgFile, setImgFile] = useState<File>();
  const [base64, setBase64] = useState("");
  const [incodeLoading, setIncodeLoading] = useState(false);
  const [previewTempUrl, setPreviewTempUrl] = useState<null | string>(null);
  const alert = useAlert();
  const handleFileInput = async (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0];
    const ext = file?.name.split(".").pop()?.toLowerCase();
    const acceptExts = ["jpg", "jpeg", "png"];
    if (!acceptExts.includes(ext ?? "")) {
      alert('"jpg", "jpeg", "png" 형식의 이미지 파일만 업로드 가능합니다.');
      return;
    }
    if ((file?.size ?? 0) > maxSize) {
      alert("5MB 미만의 이미지파일만 업로드 가능합니다.");
      return;
    }
    if (file) {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = (e) => {
        if (e.target) {
          const url = e.target.result;
          setPreviewTempUrl(url as string);
        }
      };

      setImgFile(file);
      await (async () => {
        setIncodeLoading(true);
        const base64String = await fileToBase64(file);
        setBase64(base64String as any);
        setIncodeLoading(false);
      })();

      return;
    }
  };
  return { fileName: imgFile?.name, base64, handleFileInput, incodeLoading, previewTempUrl };
};

const maxSize = 5 * 1024 * 1024;
export default function Input(props: {
  label?: string;
  boldLabel?: boolean;
  boxClassName?: string;
  searchTheme?: boolean;
  isEssential?: boolean;
  textArea?: boolean;
  attr?: React.DetailedHTMLProps<React.InputHTMLAttributes<HTMLInputElement>, HTMLInputElement>;
  textAreaAttr?: React.DetailedHTMLProps<
    React.TextareaHTMLAttributes<HTMLTextAreaElement>,
    HTMLTextAreaElement
  >;
  fileName?: string;
  imgUrl?: string;
}) {
  // const inputRef = useRef<HTMLInputElement|HTMLTextAreaElement>(null);
  const inputRef = useRef<any>(null);
  const [id] = useState(Date.now().toString());

  return (
    <Container
      $groupImgUrl={props.imgUrl}
      className={(() => {
        const classStringArr = [];
        if (props.searchTheme) {
          classStringArr.push("search-theme");
        }
        if (props?.boxClassName) {
          classStringArr.push(props.boxClassName);
        }
        return classStringArr.join(" ");
      })()}
      onClick={() => {
        if (props.searchTheme) {
          inputRef.current?.focus();
        }
      }}
    >
      {props.label && (
        <Label
          content={props.label}
          className={`${props.boldLabel ? "bold" : ""} mb-1`}
          id={id}
          isEssential={props.isEssential}
        />
      )}
      {props.attr?.type === "file" ? (
        <div className="file-input-container">
          <div className="logo-container">
            {props.imgUrl ? (
              <>
                <img src={props.imgUrl} alt="" />
              </>
            ) : (
              <></>
            )}
          </div>
          <label className="input-label-btn" htmlFor={`${props.attr.id || "group-img-file"}`}>
            <Button $colorTheme="darkGray" $buttonSize="sm" $pointerEventNone>
              <div className="d-flex ai-c gap-1">
                <div className="mt-1">
                  <RiImageAddLine fontSize={18} />
                </div>
                <div>사진 올리기</div>
              </div>
            </Button>
          </label>
          <input
            // onChange={handleFileInput}
            id={`${props.attr.id || "group-img-file"}`}
            style={{ display: "none" }}
            {...props.attr}
            className={`input-box-type`}
            accept="image/png, image/gif, image/jpeg"
          />
        </div>
      ) : (
        <>
          {/* TODO textArea 처리*/}
          {props.searchTheme && (
            <>
              <IoSearchOutline size={25} />
            </>
          )}
          {props.textArea ? (
            <textarea
              {...props.textAreaAttr}
              ref={inputRef}
              className={(props.attr?.className ?? "") + " input-box-type"}
              id={props?.attr?.id || id}
            />
          ) : (
            <input
              {...props.attr}
              ref={inputRef}
              className={
                (props.attr?.className ?? "") +
                " input-box-type" +
                `${props.searchTheme ? " searchTheme" : ""}`
              }
              id={props?.attr?.id || id}
            />
          )}
        </>
      )}
    </Container>
  );
}

Input.hooks = {
  useInput,
  useFileInput,
};

const Container = styled.div<{ $groupImgUrl?: string | null }>`
  input {
    &.searchTheme {
      &::placeholder {
        font-size: 16px !important;
      }
    }

    &:read-only {
      background-color: ${colors.gray.second};
      outline: none;
      color: ${colors.black.second};
    }
    transition: all 0.15s;
  }

  textarea {
    resize: none;
    height: 90px;
    transition: all 0.15s;
    &::placeholder {
      font-size: 12px;
    }
  }

  .file-input-container {
    display: flex;
    align-items: center;
    align-items: flex-start;
    gap: 10px;
    margin: 10px 0px;
    .input-label-btn {
      cursor: pointer;
      button {
        border-radius: 10px;
      }
    }

    .logo-container {
      border: 1px solid black;
      &,
      img {
        width: 100px;
        height: 100px;
      }
      img {
        object-fit: cover;
      }
    }
  }
  .essential-mark {
    color: red;
  }
  &.search-theme {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 10px;
    background-color: ${colors.gray.third};
    border-radius: 10px;
    padding: 10px;
    input {
      background-color: transparent;
      outline: none;
      border: none;
      flex: 1;
      height: 35px;
      font-size: 18px;
    }
  }
  display: flex;
  flex-direction: column;
`;
