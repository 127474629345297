import React, { ReactNode, useEffect, useState } from "react";
import {
  Navigation,
  Pagination,
  Scrollbar,
  Autoplay,
  EffectCoverflow,
  EffectCards,
  Manipulation,
} from "swiper/modules";
import { Swiper, SwiperSlide, useSwiper, useSwiperSlide } from "swiper/react";

import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/scrollbar";

function SwiperComponent(props: {
  slideContentList: ReactNode[];
  swiperKey: string;
  initialSlide?: number;
  goNextOnceAndStop?: boolean;
}) {
  return (
    // <div>
    <Swiper
      modules={[
        // Navigation,
        Manipulation,
        Pagination,
        // EffectCards,
        // EffectCoverflow,
        Autoplay,
      ]}
      initialSlide={props.initialSlide ?? 0}
      onSwiper={(swiper: any) => {
        if (props.goNextOnceAndStop) {
          setTimeout(() => {
            swiper.slideNext(500);
          }, 1500);
        }
      }}
      spaceBetween={10}
      loop={true}
      watchOverflow
      // centeredSlides={true}
      // slidesPerView={1.2}
      // slidesPerView={2}
      slidesPerView={1.15}
      // slidesPerView={1.5}
      // slidesPerView={1}
      // slidesPerGroup={1.2}
      // effect="cards"
      // effect="coverflow"
      navigation
      // autoplay={{ disableOnInteraction: true, delay: 2000 }}
      pagination={{ clickable: true }}
      scrollbar={{ draggable: true }}
    >
      {/* <SwiperSlide>Slide 1</SwiperSlide>;,
      <SwiperSlide>Slide 2</SwiperSlide>;,
      <SwiperSlide>Slide 3</SwiperSlide>;,
      <SwiperSlide>Slide 4</SwiperSlide>;, */}
      {props.slideContentList.map((content, idx) => {
        return <SwiperSlide key={`${props.swiperKey}-slide-${idx}`}>{content}</SwiperSlide>;
      })}
    </Swiper>
    // </div>
  );
}

export default SwiperComponent;
